.dashboard-report__filters {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__filter-selects {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
  }

  &__action-buttons {
    display: flex;
    gap: var(--gap-small);
  }
}
@include for-medium-screen-up {
  .dashboard-report__filters__filter-selects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
  }
}
