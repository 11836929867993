.box-shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

//utility classes
.capitalize {
  text-transform: capitalize;
}

.custom-fieldset {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  legend {
    background-color: white;
    color: gray;
  }
}

.red-text {
  color: red !important;
}

.green-text {
  color: green !important;
}

.gray-text {
  color: #949494;
}

.yellow-icon {
  svg {
    fill: var(--color-yellow);
  }
}

.green-icon {
  svg {
    fill: var(--color-green);
  }
}

.red-icon {
  svg {
    fill: red !important;
  }
}

.blue-icon {
  svg {
    fill: var(--color-primary-blue);
  }
}

.gray-icon {
  svg {
    fill: gray !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.link-text:hover {
  color: var(--color-primary-blue) !important;
  font-weight: 300;
}

.btn {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  padding: 0.75em 1.5em;
  background: #fff;
  border: 0;
}

.white-border-box {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
}

.flow-content > * + * {
  margin-top: var(--default-vertical-margin);
}

.small-box {
  max-width: 90px;
}

.flow-content--small > * + * {
  --default-vertical-margin: 14px;
  margin-top: var(--default-vertical-margin);
}

.loading-div {
  display: flex;
  min-height: 200px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.message-box {
  display: flex;
  margin: auto;
  padding: var(--default-vertical-margin);
  justify-content: center;
  align-items: center;
  color: gray;
  gap: 5px;
  flex-direction: column;
  @include for-medium-screen-up {
    font-size: 30px;
  }

  a {
    text-decoration: none;
    color: var(--color-primary-blue-2);
    transition: color 0.5s ease-in-out;
  }
  a:hover {
    color: var(--color-primary-blue);
  }
}

.big-messages {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  color: gray;
  @include for-medium-screen-up {
    font-size: 30px;
  }

  h3 {
    margin-right: 10px;
  }
}

// Flex Box Utilities

.flex-box {
  display: flex;
  gap: var(--gap-medium);
}

.align-items-bottom {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.full-width-children > * {
  width: 100%;
}

.dashboard-heading {
  font-family: Georgia, sans-serif;
  font-size: 3rem;
  color: var(--color-primary-blue);
  letter-spacing: -2px;
  width: 100%;
  border-bottom: 1px solid var(--color-primary-blue-opaque);
}

.dashboard-subheading {
  font-family: Georgia, sans-serif;
  font-size: 1rem;
  color: var(--color-primary-blue);
  letter-spacing: 2px;
  font-weight: bolder;
  width: 100%;
}

.error-msg-box {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  color: gray;
  @include for-medium-screen-up {
    font-size: 30px;
  }

  h3 {
    margin-right: 10px;
  }
}

.dotted-box {
  border: 1px dotted black;
}

.default-padding {
  padding: 5px;
}
