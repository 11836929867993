.stat-card {
  max-width: 450px;
  // min-width: 300px;
  text-align: center;
  padding: 2px;
  border-left: 2px solid var(--color-primary-blue);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @include for-desktop-up {
    min-width: 250px;
  }

  @include for-medium-desktop-up {
    min-width: 280px;
  }

  &__header {
    color: var(--color-gray);
    font-size: 0.7rem;
    font-weight: bolder;
    text-transform: uppercase;
  }

  &__children {
    color: #333;
    font-size: 1rem;
    word-break: break-all;
  }
}
