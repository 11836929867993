.infos {
  &__default-settings {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 700px));
  }
}
.dashboard-report {
  font-size: 1rem;
  .download-btn {
    width: 120px;
    margin-top: 20px;
    align-self: flex-start;
    background-color: hsl(202, 97, 51);
    &:hover {
      background-color: hsl(202, 97, 31);
      transform: scale(0.98);
    }
    margin-bottom: 10px;
  }
}
.dashboard-report__filters__action-buttons {
  display: flex;
  gap: var(--gap-small);
  flex-direction: row;

  button:last-child {
    background-color: green !important;
  }
}

.dashboard-report__filters__filter-selects {
  width: 100%;
  display: flex;
  gap: var(--gap-small);
  flex-direction: column;
  align-items: flex-start;
  @include for-bigger-middle-screen {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 230px));
    gap: var(--gap-small);
    align-items: center;
  }
}

.dashboard-stats {
  @include for-medium-desktop-up {
    position: absolute;
    top: 50px;
    right: 100px;
    background-color: white;
  }

  @include for-desktop-up {
    position: absolute;
    top: 50px;
    right: 100px;
    background-color: white;
  }
}
